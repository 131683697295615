import { createWebHistory, createRouter } from "vue-router";
import pathLoader from "../plugins/loader";

const routes = [
  {
    path: "/",
    name: "Home",
    component: pathLoader("home"),
  },
  {
    path: "/cart",
    name: "Cart",
    component: pathLoader("cart"),
  },
  {
    path: "/success",
    name: "Success",
    component: pathLoader("success"),
  },
  {
    path: "/programs",
    name: "Programs",
    component: pathLoader("programs"),
  },
  // {
  //     path: "/verifier-ticket",
  //     name: "Verification",
  //     component: pathLoader("verification"),
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
