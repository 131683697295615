export default {
  "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speakers"])},
  "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
  "programmes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmes"])},
  "partenaires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenaires"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "reservedPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver ma place"])},
  "firstConference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première conférence Bitcoin en Afrique Francophone"])},
  "buyMyTicketNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter mon ticket maintenant"])},
  "yourPlaceIsHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre place est ici !"])},
  "weUnderstand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous comprenons combien il peut être difficile pour les passionnés de Bitcoin de rester connectés et informés au milieu du flux incessant d'informations et de discussions. Bitcoin Mastermind est là pour offrir une expérience transformative qui va au-delà de la simple compréhension de Bitcoin. Notre mission est de vous aider à approfondir vos connaissances, élargir votre réseau et vous accompagner dans votre voyage dans l'univers du Bitcoin."])},
  "newOpportunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que vous recherchiez une expertise approfondie, une nouvelle opportunité stimulante ou simplement le désir de vous connecter avec d'autres passionnés, Bitcoin Mastermind offre l'environnement idéal pour cela. Nous ne sommes pas de simples spectateurs de l'évolution de Bitcoin, nous sommes des acteurs engagés dans sa transformation."])},
  "buyMyTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter mon ticket"])},
  "bitcoinVoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les voix de bitcoin qui parlent français dans le monde se retrouve à Bitcoin Mastermind"])},
  "inspirationSource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conférence sera une source d'inspiration et d'encouragement sans précédent."])},
  "geeksAuteur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de 100 conférenciers, entrepreneurs, chercheurs, auteurs, influenceurs, philosophes, éducateurs, activistes, développeurs, étudiants..."])},
  "blevDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blevenec : acteur clé l'innovation technologique. Renommé en tant que fondateur de Blemama, Blevenec est un précurseur dans le domaine du Bitcoin au Bénin. Son accompagnement de plus de 10000 personnes formées à propos du Bitcoin renforce encore davantage son rôle central dans le façonnement de l'industrie. En tant que fondateur et CEO de Blemama, il continue de diriger et de stimuler l'innovation, repoussant les limites de ce qui est possible dans le monde des technologies ."])},
  "ourPartners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos Partenaires"])},
  "wantToBePartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous aussi être partenaire ?"])},
  "beVisible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyez visible lors du plus grand événement bitcoin en Afrique Francophone !"])},
  "participantSaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce que nos participants disent de nous !"])},
  "prudentBitcoiner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un Bitcoiner prudent sécurise son ticket maintenant !"])},
  "findPack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrouvez dans le pack des 3 tickets, ce qui correspond le mieux à votre profil. Il y en a pour tous. "])},
  "popularOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre populaire"])},
  "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions fréquemment posées !"])},
  "faqT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quand et où se déroule l'événement ?"])},
  "ans1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’événement se déroulera au Bénin, Diaspora Hôtel, Ouidah du 05 au 07 Juillet 2024."])},
  "faqT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment puis-je m'inscrire ou réserver ma place ?"])},
  "ans2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservez votre place en choisissant votre ticket <a href=\"https://btcmastermind.xyz/#tickets/\">ici</a>  et en validant le choix par paiement du montant indiquer selon le moyen choisit."])},
  "faqT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans quelle langue se déroulera l'événement ?"])},
  "ans3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conférence se déroulera exclusivement en Français. Les panels, activités et jeux se dérouleront aussi en français. Les participants viennent majoritairement des pays de l’Afrique francophone. "])},
  "faqT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est-ce que l'événement sera enregistré ou diffusé en direct en ligne ?"])},
  "ans4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’événement sera en partie enregistrée : les panels, workshops et ateliers. Cependant, elle ne sera pas diffusée en direct en ligne. "])},
  "faqT5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelles sont les politiques en matière d'annulation ou de remboursement ?"])},
  "ans5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous offrons la possibilité d'annuler votre achat de billet dans les 72 heures suivant son acquisition, avec un remboursement complet. Ce remboursement sera traité dans un délai de 1 à 2 semaines. Veuillez noter que cette option est disponible uniquement jusqu'à un mois avant la date de l'événement."])},
  "faqT6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y a-t-il des possibilités de bénévolat ou d'implication dans l'organisation de l'événement ?"])},
  "ans6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous offrons des possibilités de bénévolat et d'implication dans l'organisation de notre événement. Si vous êtes intéressé à contribuer à la réussite de Bitcoin Mastermind 2024 en tant que bénévole, veuillez réserver votre ticket puis contactez nous ici."])},
  "stayInformed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restez informez des nouveautés et des promotions"])},
  "putEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignez votre adresse mail."])},
  "beTheFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous serez le premier à être informé du programme et des nouvelles "])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyez"])},
  "partnerEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laissez nous un mail"])},
  "yourEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse mail"])},
  "buyYourTicketNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achetez votre ticket maintenant"])},
  "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier"])},
  "cartEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier est vide"])},
  "continuePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer le paiement"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénoms"])},
  "promoCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code promo"])},
  "allowTermsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis d’accord avec les Termes et Conditions"])},
  "signUpToNewletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je m’inscris à la newsletter de Bitcoin Mastermind"])},
  "useThisCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez ce code · "])},
  "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au panier"])},
  "removeFromCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer du panier"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "paymentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement effectué avec succès"])},
  "buyPassDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’achat de votre ticket de participation à Bitcoin Mastermind 2024 a été effectué avec succès."])},
  "verifyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre boîte email ou les spams."])},
  "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
  "verifyTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier votre/vos ticktet(s) avec votre email"])},
  "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement échoué"])},
  "buyPassFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paiement pour l’achat de votre ticket de participation à Bitcoin Mastermind 2024 n’a pas été effectué."])},
  "verifySolde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre solde et réessayez."])},
  "justOnePass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups! Ce code promo n'achète qu'un seul pass."])},
  "justPassOneDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups! Ce code promo n'achète que le pass ONE DAY."])}
}