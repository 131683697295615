<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  components: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss">
@import "src/assets/style/sass/variables";
#app {
  font-family: $font-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input,
button,
select,
textarea {
  outline: none !important;
  &:focus,
  &:active {
    outline: none;
  }
}
</style>
